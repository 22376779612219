import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

import { Options } from 'ky';
import { Address } from 'viem';

import {
  getOneInchGasPrices,
  getOneInchNetwork,
  getOneInchQuote,
  getOneInchTerms,
  getOneInchTokenAllowance,
  getOneInchTokens,
  getOneInchTxApprove,
  getTokenPriceInUSD,
  makeOneInchTokensSwap,
  storeSwapTx,
} from '@api';

export const oneInchQueryKeys = {
  getNetworks: ['getOneInchNetworks'],
  getTokens: (networkId = -99999) => ['getOneInchTokens', networkId],
  getQuote: ['getOneInchQuote'],
  getAllowance: ['getOneInchAllowance'],
  increaseAllowance: ['increaseOneInchAllowance'],
  storeSwapTx: ['storeSwapTx'],
  getOneInchGasPrices: ['getOneInchGasPrices'],
  getTokenPriceInUSD: (networkId = -99999, tokenAddress: Address = '0x00-0') => [
    'getTokenPriceInUSD',
    networkId,
    tokenAddress,
  ],
  getTerms: (userId: number) => ['getOneInchTerms', userId],
};

export const useGetOneInchNetworksQuery = (
  options: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getOneInchNetwork>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  > = {},
) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getNetworks,
    queryFn: () => getOneInchNetwork(),
    ...options,
  });
};

export const useGetOneInchTokensQuery = ({
  networkId,
  options,
}: {
  networkId?: Parameters<typeof getOneInchTokens>[0];
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getOneInchTokens>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >;
}) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getTokens(networkId),
    queryFn: () => (networkId ? getOneInchTokens(networkId) : []),
    gcTime: 0,
    staleTime: 0,
    ...options,
  });
};

export const useGetOneInchQuoteQuery = ({
  apiOptions,
  options,
}: {
  apiOptions?: Parameters<typeof getOneInchQuote>[0];
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getOneInchQuote> | undefined>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >;
}) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getQuote,
    queryFn: async ({ signal }) => {
      if (!apiOptions) {
        return undefined;
      }

      try {
        return await getOneInchQuote(apiOptions, signal);
      } catch (error) {
        if (signal?.aborted) {
          return undefined;
        }

        throw error;
      }
    },
    gcTime: 0,
    staleTime: 0,
    ...options,
  });
};

export const useGetOneInchTokenAllowanceQuery = ({
  networkId,
  apiOptions,
  options,
}: {
  networkId?: Parameters<typeof getOneInchTokenAllowance>[0];
  apiOptions?: Parameters<typeof getOneInchTokenAllowance>[1];
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getOneInchTokenAllowance>> | undefined,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >;
}) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getAllowance,
    queryFn: () =>
      networkId && apiOptions ? getOneInchTokenAllowance(networkId, apiOptions) : undefined,
    gcTime: 0,
    staleTime: 0,
    ...options,
  });
};

export const useIncreaseOneInchTokenAllowanceMutation = () => {
  return useMutation({
    mutationKey: oneInchQueryKeys.increaseAllowance,
    mutationFn: getOneInchTxApprove,
  });
};

export const useSwapTokensMutation = () => {
  return useMutation({
    mutationKey: oneInchQueryKeys.increaseAllowance,
    mutationFn: makeOneInchTokensSwap,
  });
};

export const useGetTokenPriceInUSDQuery = ({
  networkId,
  tokenAddress,
  options,
}: {
  networkId?: Parameters<typeof getTokenPriceInUSD>[0];
  tokenAddress?: Parameters<typeof getTokenPriceInUSD>[1];
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getTokenPriceInUSD>> | undefined,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >;
}) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getTokenPriceInUSD(networkId, tokenAddress),
    queryFn: () =>
      networkId && tokenAddress ? getTokenPriceInUSD(networkId, tokenAddress) : undefined,
    gcTime: 30000,
    staleTime: 30000,
    retry: 3,
    ...options,
  });
};

export const useStoreSwapTxMutation = () => {
  return useMutation({ mutationKey: oneInchQueryKeys.storeSwapTx, mutationFn: storeSwapTx });
};

export const useGetOneInchGasPricesQuery = ({
  networkId,
  options,
}: {
  networkId?: Parameters<typeof getOneInchGasPrices>[0];
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getOneInchGasPrices>> | undefined,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >;
} = {}) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getOneInchGasPrices,
    queryFn: () => (networkId ? getOneInchGasPrices(networkId) : undefined),
    gcTime: 0,
    staleTime: 0,
    retry: 3,
    ...options,
  });
};

export const prefetchOneInchTermsQuery = async (
  clientQuery: QueryClient,
  userId: number,
  options: Options,
) => {
  const key = oneInchQueryKeys.getTerms(userId);

  await clientQuery.prefetchQuery({ queryKey: key, queryFn: () => getOneInchTerms(options) });

  return clientQuery.getQueryData<ReturnType<typeof getOneInchTerms>>(key);
};

export const useGetOneInchTermsQuery = (
  userId: number,
  options?: Pick<
    UndefinedInitialDataOptions<Awaited<ReturnType<typeof getOneInchTerms>>, unknown, unknown, any>,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: oneInchQueryKeys.getTerms(userId),
    queryFn: () => getOneInchTerms(),
    gcTime: 0,
    staleTime: 0,
    ...options,
  });
};
