import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { DEFAULT_LIMIT, getMagicWallCategoriesList, getMagicWallList, WallRequest } from '@api';

const magicWallQueryKeys = {
  infiniteList: (searchParams: WallRequest) => ['infiniteHotOffersList', searchParams],
  categoriesList: ['categoriesList'],
} as const;

export const useMagicWallInfinityQuery = (params: WallRequest) => {
  return useInfiniteQuery({
    queryKey: magicWallQueryKeys.infiniteList(params),
    queryFn: ({ pageParam: offset }) =>
      getMagicWallList({
        searchParams: {
          ...params,
          offset,
          limit: DEFAULT_LIMIT,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) =>
      lastPage.data?.length === DEFAULT_LIMIT ? pages.length * DEFAULT_LIMIT : undefined,
    gcTime: 0,
  });
};

export const useMagicWallCategoriesQuery = () =>
  useQuery({
    queryKey: magicWallQueryKeys.categoriesList,
    queryFn: () => getMagicWallCategoriesList(),
  });
