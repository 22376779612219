import {
  QueryClient,
  UndefinedInitialDataOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import type { UndefinedInitialDataInfiniteOptions } from '@tanstack/react-query/src/infiniteQueryOptions';

import { addDays } from 'date-fns';

import {
  changeMembershipTier,
  confirmDowngrade,
  getDepositWalletAddress,
  getMembershipDashboard,
  getMembershipTiers,
  getSelfMembershipHistory,
  GetSelfMembershipHistoryFilterSearchParams,
  GetSelfMembershipHistorySearchParams,
  getTierChangePrice,
  getUserData,
  MembershipTierId,
  sendMembershipChangeVerificationCode,
  upgradeMembershipTierForFree,
  UserKarma,
  verifyDowngradeMembershipTier,
} from '@api';

import { karmaQueryKeys, SELF_KARMA_DEFAULT_PARAMS } from './karma';
import { AUTH_USER_QUERY_KEY } from './keys';
import { setSessionUserQuery, updateSessionUserMagicIdQueryData } from './session';

const membershipQueryKeys = {
  tiers: ['membershipTiers'],
  getTierChangePrice: (tier: MembershipTierId) => ['getTierChangePrice', tier],
  getUserCustodialWallet: ['getUserCustodialWallet'],
  getDepositWalletAddress: ['getDepositWalletAddress'],
  amountToLock: ['getAmountToLock'],
  withdraw: ['getWithdrawData'],
  updateTier: ['updateMembershipTier'],
  dashboard: (userId: number | null) => {
    return ['membershipDashboard', userId];
  },
  selfHistory: (params?: GetSelfMembershipHistorySearchParams) => [
    AUTH_USER_QUERY_KEY,
    'selfMembershipHistory',
    params,
  ],
};

export const useSelfMembershipHistoryInfiniteQuery = (
  filters: GetSelfMembershipHistoryFilterSearchParams = {},
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getSelfMembershipHistory>>,
      unknown,
      unknown,
      any,
      number
    >,
    'enabled' | 'placeholderData'
  >,
) => {
  return useInfiniteQuery({
    queryKey: membershipQueryKeys.selfHistory(filters),
    queryFn: ({ pageParam }) =>
      getSelfMembershipHistory({ searchParams: { ...filters, offset: pageParam } }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) =>
      lastPage.data.length === filters?.limit ? filters?.limit * pages.length : undefined,
    staleTime: 0,
    ...options,
  });
};

export const useMembershipTierList = (
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getMembershipTiers>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: membershipQueryKeys.tiers,
    queryFn: () => getMembershipTiers(),
    ...options,
  });
};

export const useGetTierChangePriceMutation = () => {
  return useMutation({ mutationFn: getTierChangePrice });
};

export const useGetTierChangePriceQuery = (
  targetTier: MembershipTierId,
  options?: { enabled: boolean },
) => {
  return useQuery({
    queryKey: membershipQueryKeys.getTierChangePrice(targetTier),
    queryFn: () => getTierChangePrice({ searchParams: { targetTier } }),
    ...options,
  });
};

export const useConfirmDowngradeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const tierChangeResponse = await confirmDowngrade();

      if ('type' in tierChangeResponse && tierChangeResponse.type === 'TIER') {
        const selfUser = await getUserData();
        const membershipDashboard = await getMembershipDashboard();

        return { selfUser, tierChangeResponse, membershipDashboard };
      }

      return { tierChangeResponse };
    },
    onSuccess(data) {
      const tierChangeResponse = data?.tierChangeResponse;
      const selfUser = data?.selfUser;
      const membershipDashboard = data?.membershipDashboard;

      if (selfUser) {
        setSessionUserQuery(queryClient, selfUser);
      }

      if (membershipDashboard) {
        queryClient.setQueryData(
          membershipQueryKeys.dashboard(selfUser?.id ?? null),
          membershipDashboard,
        );
      }

      return tierChangeResponse;
    },
  });
};

export const useChangeMembershipTierMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: Parameters<typeof changeMembershipTier>[0]) => {
      const tierChangeResponse = await changeMembershipTier(params);

      if ('type' in tierChangeResponse && tierChangeResponse.type === 'TIER') {
        const selfUser = await getUserData();
        const membershipDashboard = await getMembershipDashboard();

        return { selfUser, tierChangeResponse, membershipDashboard };
      }

      return { tierChangeResponse };
    },
    onSuccess(data) {
      const tierChangeResponse = data?.tierChangeResponse;
      const selfUser = data?.selfUser;
      const membershipDashboard = data?.membershipDashboard;

      if (selfUser) {
        setSessionUserQuery(queryClient, selfUser);
      }

      if (membershipDashboard) {
        queryClient.setQueryData(
          membershipQueryKeys.dashboard(selfUser?.id ?? null),
          membershipDashboard,
        );
      }

      return tierChangeResponse;
    },
  });
};

export const useUpgradeMembershipTierForFreeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: upgradeMembershipTierForFree,
    onSuccess: (data) => {
      const selfKarmaQueryKey = karmaQueryKeys.selfKarma(SELF_KARMA_DEFAULT_PARAMS);

      queryClient.setQueryData(selfKarmaQueryKey, (oldData?: UserKarma) => {
        if (!oldData) {
          return oldData;
        }

        return {
          ...oldData,
          user: {
            ...oldData.user,
            membership: {
              tier: data.attributes.key,
            },
          },
        };
      });

      updateSessionUserMagicIdQueryData(queryClient, {
        membershipTier: {
          attributes: data.attributes,
        },
      });
    },
  });
};

export const useSendMembershipTierChangeVerificationCode = () => {
  return useMutation({ mutationFn: () => sendMembershipChangeVerificationCode() });
};

export const useVerifyDowngradeMembershipTier = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ activationCode }: { activationCode: string }) => {
      return verifyDowngradeMembershipTier({
        json: {
          activationCode,
        },
      });
    },
    onSuccess: ({ data }) => {
      // NOTE: backend should return us this data
      const deactivationLock = addDays(new Date(), 30);

      updateSessionUserMagicIdQueryData(queryClient, {
        membershipTier: {
          attributes: {
            lockAmount: data.attributes.amountToLock,
            deactivationLock: deactivationLock.toISOString(),
            description: data.attributes.description,
            key: data.attributes.key,
            name: data.attributes.name,
          },
        },
      });
    },
  });
};

export const useGetUserCustodialWalletQuery = (
  params: Parameters<typeof changeMembershipTier>[0],
  options: { enabled?: boolean } = {},
) => {
  return useQuery({
    queryKey: membershipQueryKeys.getUserCustodialWallet,
    queryFn: () => changeMembershipTier(params),
    ...options,
  });
};

export const useGetDepositWalletAddressQuery = (queryOptions?: { enabled: boolean }) => {
  return useQuery({
    queryKey: membershipQueryKeys.getDepositWalletAddress,
    queryFn: () => getDepositWalletAddress(),
    ...queryOptions,
  });
};

export const prefetchMembershipTierListQuery = async (
  clientQuery: QueryClient,
  options?: Parameters<typeof getMembershipTiers>[0],
) => {
  await clientQuery.prefetchQuery({
    queryKey: membershipQueryKeys.tiers,
    queryFn: () => getMembershipTiers(options),
  });
};

export const useMembershipDashboard = (userId: number | null) => {
  return useQuery({
    queryKey: membershipQueryKeys.dashboard(userId),
    queryFn: () => getMembershipDashboard(),
  });
};
